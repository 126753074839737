<template>
  <div id="app">
    <router-view @updateStep="updateStep" />
    <notifications group="foo" position="top right">
      <template slot="body" slot-scope="props">
        <div class="vue-notification-template vue-notification custom">
          <img :src="props.item.text" alt="i" />
          <div class="notification-title">
            {{ props.item.title }}
          </div>
        </div>
      </template>
    </notifications>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import { uuid } from 'vue-uuid'
import { stepNumbers } from './utils/stepNumbers'

export default {
  name: 'app',
  data: () => ({
    sessionToken: null
  }),
  methods: {
    ...mapActions({
      sendMetrics: 'sendMetrics',
      addSessionId: 'addSessionId',
      addObjectId: 'addObjectId',
      markCalculationAsSaved: 'markCalculationAsSaved',
      addPublicLink: 'addPublicLink'
    }),
    sendMetricsMethod(stepNumber) {
      const { sessionToken } = this
      this.sendMetrics({
        stepNumber,
        sessionToken
      })
    },
    updateStep(stepNumber) {
      this.sessionToken =
        stepNumber === stepNumbers.location && !this.sessionToken
          ? uuid.v1()
          : this.sessionToken
      this.sendMetricsMethod(stepNumber)
    }
  },
  mounted() {
    window.parent.postMessage({ auth: null }, '*')
    window.addEventListener('message', (evt) => {
      const hasAuthField = Object.keys(evt.data)?.some((field) => field === 'auth')
      const hasObjIdField = Object.keys(evt.data)?.some((field) => field === 'objId')
      const isSavingSuccessful = Object.keys(evt.data)?.some((field) => field === 'saved')

      if (hasAuthField) {
        this.addSessionId(evt.data.auth)
      }
      if (hasObjIdField) {
        this.addObjectId(evt.data.objId)
      }

      if (isSavingSuccessful) {
        this.markCalculationAsSaved(evt.data.saved)
        this.addPublicLink(evt.data.saved)
      }
    })
  }
}
</script>
<style lang="sass">
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800,900&display=swap&subset=cyrillic,cyrillic-ext,latin-ext')
#app
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  text-align: center
  color: $black
  .container
    max-width: rem(960)
body
  font-family: 'Montserrat', sans-serif
  margin: 0
  background: $ec
  overflow-x: hidden
  &.modal-open
    overflow-y: hidden
.wizard
  &__buttons
    &-nav
      justify-content: space-between
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0
input[type='number']
  -moz-appearance: textfield
input
  &:invalid
    box-shadow: none
  &:-moz-submit-invalid
    box-shadow: none
  &:-moz-ui-invalid
    box-shadow: none
</style>
