export const shapes = {
  rectangle: 'rectangle',
  corner: 'corner',
  't-shaped': 't-shaped',
  'p-shaped': 'p-shaped',
  'x-shaped': 'x-shaped'
}

export const figures = [
  {
    form: shapes.rectangle,
    src: require('@/assets/img/forms/rectangle.svg'),
    srcActive: require('@/assets/img/forms/rectangle--active.svg'),
    params: {
      a: 3,
      b: 2
    }
  },
  {
    form: shapes.corner,
    src: require('@/assets/img/forms/corner.svg'),
    srcActive: require('@/assets/img/forms/corner--active.svg'),
    params: {
      a: 2,
      b: 3,
      c: 2,
      d: 1
    }
  },
  {
    form: shapes['t-shaped'],
    src: require('@/assets/img/forms/t-shaped.svg'),
    srcActive: require('@/assets/img/forms/t-shaped--active.svg'),
    params: {
      a: 2,
      b: 3,
      c: 1,
      d: 2,
      e: 1
    }
  },
  {
    form: shapes['p-shaped'],
    src: require('@/assets/img/forms/p-shaped.svg'),
    srcActive: require('@/assets/img/forms/p-shaped--active.svg'),
    params: {
      a: 2,
      b: 1,
      c: 2,
      d: 2,
      e: 1,
      f: 1
    }
  },
  {
    form: shapes['x-shaped'],
    src: require('@/assets/img/forms/x-shaped.svg'),
    srcActive: require('@/assets/img/forms/x-shaped--active.svg'),
    params: {
      a: 1,
      b: 1,
      f: 1,
      c: 1,
      d: 1,
      e: 1,
      i: 1,
      g: 1
    }
  }
]

export const defaultFormParams = {
  ...figures.find(figure => figure.form === shapes.rectangle).params
}

const widthLabel = 'Ширина'
const lengthLabel = 'Длина'

export const figuresLabels = {
  [shapes.rectangle]: {
    a: widthLabel,
    b: lengthLabel
  },
  [shapes.corner]: {
    a: widthLabel,
    b: widthLabel,
    c: lengthLabel,
    d: lengthLabel
  },
  [shapes['t-shaped']]: {
    a: widthLabel,
    b: widthLabel,
    c: lengthLabel,
    d: lengthLabel,
    e: lengthLabel
  },
  [shapes['p-shaped']]: {
    a: widthLabel,
    b: widthLabel,
    c: widthLabel,
    d: lengthLabel,
    e: lengthLabel,
    f: lengthLabel
  },
  [shapes['x-shaped']]: {
    a: widthLabel,
    b: widthLabel,
    f: widthLabel,
    c: lengthLabel,
    d: lengthLabel,
    e: lengthLabel,
    i: lengthLabel,
    g: lengthLabel
  },
  any: {
    h: 'Высота здания'
  }
}

export const figuresSetZero = {
  [shapes.rectangle]: {
    a: { allow: false, bounded: [] },
    b: { allow: false, bounded: [] }
  },
  [shapes.corner]: {
    a: { allow: false, bounded: [] },
    b: { allow: false, bounded: [] },
    c: { allow: false, bounded: [] },
    d: { allow: false, bounded: [] }
  },
  [shapes['t-shaped']]: {
    a: { allow: false, bounded: [] },
    b: { allow: false, bounded: [] },
    c: { allow: false, bounded: [] },
    d: { allow: false, bounded: [] },
    e: { allow: false, bounded: [] }
  },
  [shapes['p-shaped']]: {
    a: { allow: false, bounded: [] },
    b: { allow: false, bounded: [] },
    c: { allow: false, bounded: [] },
    d: { allow: false, bounded: [] },
    e: { allow: false, bounded: [] },
    f: { allow: false, bounded: [] }

  },
  [shapes['x-shaped']]: {
    a: { allow: true, bounded: ['g', 'i'] },
    b: { allow: false, bounded: [] },
    f: { allow: true, bounded: ['c', 'e'] },
    c: { allow: true, bounded: [] },
    d: { allow: false, bounded: [] },
    e: { allow: true, bounded: [] },
    i: { allow: true, bounded: [] },
    g: { allow: true, bounded: [] }
  }
}
