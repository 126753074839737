import { figuresLabels } from '@/utils/figures'

export const isValidNumber = (number, min = 0, max = Infinity) =>
  !isNaN(parseFloat(number)) && number >= min && number <= max

/**
 * Ограничение числа в диапазоне
 * @param num Исходное число
 * @param min Граничное значение снизу
 * @param max Граничное значение сверху
 */
export const clamp = (num, min, max) => Math.min(Math.max(Number(num), min), max)

export const getRandomId = () => Math.random().toString(36).substr(2, 9)

export const getFormattedWord = (words, count) => {
  const cases = [2, 0, 1, 1, 1, 2]
  const currentWord =
    count % 100 > 4 && count % 100 < 20 ? 2 : cases[count % 10 < 5 ? count % 10 : 5]
  return words[currentWord]
}

const colors = {
  green: '#68CA66',
  violet: '#BEA8FF',
  orange: '#FF6928',
  blue: '#1FAFED',
  yellow: '#F4BE34',
  grey: '#999999',
  purple: '#c10acd',
  red: '#E30713',
  darkblue: '#2e81ff'
}

export const figureColors = {
  a: colors.green,
  b: colors.violet,
  c: colors.orange,
  d: colors.yellow,
  e: colors.blue,
  f: colors.purple,
  g: colors.darkblue,
  i: colors.red
}

export const formsComponents = {
  rectangle: () => import('@/components/dump/Figures/Rectangle'),
  corner: () => import('@/components/dump/Figures/Corner'),
  't-shaped': () => import('@/components/dump/Figures/T-Shaped'),
  'p-shaped': () => import('@/components/dump/Figures/P-Shaped'),
  'x-shaped': () => import('@/components/dump/Figures/X-Shaped')
}

export const normalizeBackendResults = results => {
  return {
    total: results.total,
    sectors: results.sectors.map(sector => {
      return {
        ...sector,
        waterproofFixation: [
          sector.waterproofFixation.windpressure.map((item) => {
            return {
              ...item,
              value: item.value.map(v => /^0\s/.test(v) ? '-' : v)
            }
          }),
          sector.waterproofFixation.isolation,
          sector.waterproofFixation.waterproofing
        ]
      }
    })
  }
}

export const isSomeMinorResultZoneUnsetted = minorResult =>
  Object.values(minorResult).some(item => item !== null)

export const isSomeSectorMinorResultZoneUnsetted = sectors => {
  return sectors.some(sector => isSomeMinorResultZoneUnsetted(sector.minorResult))
}

export const getZoneOptionsWithoutRollWidth = recommendedOptions => {
  return Object.entries(recommendedOptions).filter(([key, value]) => key !== 'rollWidth')
}

export const normalizeSectorParamsView = sector => {
  const { building } = sector
  const { height, params, form } = building
  const currentLabels = figuresLabels[form]
  const buildingHeight = {
    title: `${figuresLabels['any'].h}`,
    val: `${height} м`,
    id: getRandomId()
  }
  const sizes = Object.entries(params).map(([key, value]) => {
    return {
      title: `${currentLabels[key]} ${key.toLowerCase()}`,
      val: `${value} м`,
      id: getRandomId()
    }
  })
  sizes.unshift(buildingHeight)
  return sizes
}

export const getMountingName = (mountingTypes, sector) => {
  const sectorMounting = sector.material.mounting
  return mountingTypes.find(type => type.value === sectorMounting).name
}

export function getObjectId() {
  const objId = localStorage.getItem('objId')

  if (!objId || objId === 'undefined') return null

  const hasDouble = objId.split(',').length > 1
  localStorage.removeItem('objId')

  return hasDouble ? objId.split(',')[0] : objId
}
