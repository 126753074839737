import Vue from 'vue'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueTippy, { TippyComponent } from 'vue-tippy'
import Notifications from 'vue-notification'
import Vuelidate from 'vuelidate'
import UnsupportBrowsers from './UnsupportBrowsers.vue'
import App from './App.vue'
import { router } from './router'
import store from './store'

import 'swiper/dist/css/swiper.css'
import 'vue-loading-overlay/dist/vue-loading.css'

Vue.use(VueTippy)
Vue.component('tippy', TippyComponent)
Vue.use(VueAwesomeSwiper)
Vue.use(Vuelidate)
Vue.use(Notifications)
Vue.config.productionTip = false

// Internet Explorer 6-11
const isIE = false || !!document.documentMode
// Edge 20+
const isEdge = !isIE && !!window.StyleMedia
if (!isIE && !isEdge) {
  new Vue({
    router,
    store,
    render: h => h(App),
    methods: {
      onBodyScroll() {
        document.body.classList.remove('modal-open')
      },
      offBodyScroll() {
        window.parent.postMessage({ scrollTop: true }, '*')
        document.body.classList.add('modal-open')
      }
    },
    mounted() {
      const observer = new ResizeObserver(entries => {
        entries.forEach(entry => {
          window.parent.postMessage({ height: entry.contentRect.height }, '*')
        })
      })
      observer.observe(document.body)
      window.addEventListener('beforeunload', () => {
        observer.disconnect()
      })
    }
  }).$mount('#app')
  Vue.directive('click-outside', {
    bind(el, binding) {
      el.addEventListener('click', e => e.stopPropagation())
      document.body.addEventListener('click', binding.value)
    },
    unbind(el, binding) {
      document.body.removeEventListener('click', binding.value)
    }
  })
} else {
  new Vue({
    render: h => h(UnsupportBrowsers),
    methods: {},
    mounted() {}
  }).$mount('#app')
}
