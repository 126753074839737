export const sendStatistic = label => window.dataLayer.push({ 'event': label })
export const calcLabel = 'roofload'

export const statisticLabels = {
  prefooter: {
    pdf: `calc-${calcLabel}-pdf`,
    xls: `calc-${calcLabel}-xls`,
    print: `calc-${calcLabel}-print`,
    save: `calc-${calcLabel}-save-object`
  },
  steps: {
    step1: `calc-${calcLabel}-step1`,
    step2: `calc-${calcLabel}-step2`,
    step3: `calc-${calcLabel}-step3`,
    step4: `calc-${calcLabel}-step4`,
    step5: `calc-${calcLabel}-step5`,
    step6: `calc-${calcLabel}-step6`,
    result: `calc-${calcLabel}-step7`
  }
}
export const steps = Object.keys(statisticLabels.steps)
