import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import { cloneDeep } from 'lodash'
import { getContent, getResultFromDB, getTotalResult, checkTNToken } from '@/api'
import { shapes, defaultFormParams } from '@/utils/figures'
import {
  getRandomId,
  normalizeBackendResults,
  isSomeMinorResultZoneUnsetted, getObjectId
} from '@/utils'
import { sendMetrics } from '@/api/metrics'
import { PRESSURE_UNITS } from '@/components/helper'

Vue.use(Vuex)

const density = 'density'
const pages = {
  result: 'result'
}

const defaultMinorResult = {
  C: null,
  D: null,
  E: null
}

export default new Vuex.Store({
  state: {
    hash: null,
    TNTokenStatus: false,
    TNToken: null,
    saveCalculationLink: null,
    pages: {
      location: {
        isLoading: true
      },
      result: {
        isLoading: false
      }
    },
    userInfo: {
      result: null,
      sessionId: null,
      objectId: null
    },
    result: {
      total: {},
      sectors: {}
    },
    publicLink: null,
    minorResults: null,
    disclaimer: null,
    disclaimerIndex: 0,
    insulationMaterials: [],
    insulationTypes: [],
    insulationMethods: [],
    materials: [],
    mounting: [],
    categories: [],
    albums: [],
    fasteners: [],
    location: {
      zone: '',
      pressure: '',
      type: null,
      coefficient: 1.4,
      pressureUnit: PRESSURE_UNITS.pa.name,
      converted: null
    },
    sectors: [
      {
        base: {
          id: null,
          loadBearingCapacity: {
            isCalculate: false,
            value: 400
          },
          type: {
            id: null,
            isCustom: false,
            size: null,
            isDoubleFastener: false,
            doubleFastenerBySide: {
              C: false,
              E: false,
              D: false
            }
          }
        },
        insulation: {
          isEnabled: true,
          data: {
            method: null,
            type: null,
            thickness: 0
          }
        },
        material: {
          mounting: 'mechanical',
          id: null,
          rollWidthOption: null
        },
        minorResult: {
          C: null,
          D: null,
          E: null
        },
        uid: 'mzi3iy0oc',
        height: 20,
        sideB: 8,
        sideL: 12,
        sectorsCount: 1,
        inAppStep: 'Base',
        sectorName: 'Участок 1',
        sectorImage: '',
        building: {
          form: shapes.rectangle,
          height: 20,
          params: { ...defaultFormParams }
        },
        isDependOnRollsSize: null
      }
    ],
    defaultSector: {
      base: {
        id: null,
        loadBearingCapacity: {
          isCalculate: false,
          value: 400
        },
        type: {
          id: null,
          isCustom: false,
          size: null,
          isDoubleFastener: false,
          doubleFastenerBySide: {
            C: false,
            E: false,
            D: false
          }
        }
      },
      insulation: {
        isEnabled: true,
        data: {
          method: null,
          type: null,
          thickness: 0
        }
      },
      material: {
        mounting: 'mechanical',
        id: null,
        rollWidthOption: null
      },
      minorResult: {
        C: null,
        D: null,
        E: null
      },
      uid: null,
      height: 20,
      sideB: 8,
      sideL: 12,
      sectorsCount: 1,
      inAppStep: 'Base',
      sectorName: '',
      sectorImage: '',
      building: {
        form: shapes.rectangle,
        height: 20,
        params: { ...defaultFormParams }
      },
      isDependOnRollsSize: null
    },
    defaultSvgParams: {
      width: 700,
      height: 500,
      baseHeight: 4,
      baseWidth: 6,
      navScale: 0.1765
    },
    defaultBaseType: {
      id: null,
      isCustom: false,
      size: null,
      isDoubleFastener: false,
      doubleFastenerBySide: {
        C: false,
        E: false,
        D: false
      }
    },
    defaultInsulationData: {
      method: null,
      type: null,
      thickness: 0
    },
    messages: null
  },
  mutations: {
    UPDATE_TN_TOKEN_STATUS: (state, TNTokenStatus) => {
      state.TNTokenStatus = TNTokenStatus
    },
    UPDATE_TN_TOKEN: (state, token) => {
      state.TNToken = token
    },
    UPDATE_SECTOR_BUILDING_FORM(state, { sectorIndex, form, params }) {
      state.sectors[sectorIndex].building.form = form
      state.sectors[sectorIndex].building.params = { ...params }
    },
    UPDATE_SECTOR_BUILDING_PARAM(state, { sectorIndex, param, value }) {
      state.sectors[sectorIndex].building.params[param] = value
    },
    UPDATE_SECTOR_BUILDING_HEIGHT(state, { sectorIndex, value }) {
      state.sectors[sectorIndex].building.height = value
    },
    UPDATE_LOCATION_PARAM(state, payload) {
      state.location[payload.param] = payload.val
    },
    UPDATE_SECTOR_BASE(state, payload) {
      state.sectors[payload.id].base.id = payload.material
      if (!payload.hasTypes) {
        delete state.sectors[payload.id].base.type
      } else {
        Vue.set(state.sectors[payload.id].base, 'type', cloneDeep(state.defaultBaseType))
      }
    },
    UPDATE_SECTOR_BASE_TYPE(state, payload) {
      state.sectors[payload.id].base.type.id = payload.material
      state.sectors[payload.id].base.type.size = payload.size
      if (state.sectors[payload.id].base.type?.isCustom) {
        state.sectors[payload.id].base.type.isCustom = false
      }
    },
    UPDATE_SECTOR_BASE_TYPE_MOD(state, payload) {
      state.sectors[payload.id].base.type.id = null
      if (payload.mod) {
        state.sectors[payload.id].base.type.isCustom = payload.mod
      }
      state.sectors[payload.id].base.type.size = null
    },
    UPDATE_SECTOR_BASE_TYPE_SIZE(state, payload) {
      state.sectors[payload.id].base.type.size = payload.size
    },
    UPDATE_SECTOR_LOAD_BEARING_PARAM(state, { sectorIndex, param, val }) {
      state.sectors[sectorIndex].base.loadBearingCapacity[param] = val
    },
    UPDATE_INSULATION_NECESSITY(state, { id, val }) {
      state.sectors[id].insulation.isEnabled = val
      if (!val) {
        delete state.sectors[id].insulation.data
      } else {
        const target = state.sectors[id].material
        if (target.id) {
          target.id = null
        }
        Vue.set(state.sectors[id].insulation, 'data', cloneDeep(state.defaultInsulationData))
      }
    },
    UPDATE_INSULATION_PARAM(state, { id, param, val }) {
      const target = state.sectors[id].material
      if (target.id) {
        target.id = null
      }
      state.sectors[id].insulation.data[param] = val
    },
    UPDATE_SECTOR_MATERIAL_MOUNTING(state, { sectorIndex, originalMounting }) {
      const target = state.sectors[sectorIndex].material
      if (target.id) {
        target.id = null
      }
      target.mounting = originalMounting.value
      if (originalMounting.hasTypes) {
        const [firstType] = originalMounting.types
        Vue.set(target, 'type', firstType.value)
      } else {
        delete target.type
        if (target.hasOwnProperty(density)) {
          delete target[density]
        }
      }
    },
    UPDATE_SECTOR_MATERIAL_PARAM(state, { id, param, val, isNeedAddDensity = false }) {
      const target = state.sectors[id].material
      target[param] = val
      if (isNeedAddDensity) {
        Vue.set(target, density, 1000)
      } else {
        if (target.hasOwnProperty(density)) {
          delete target[density]
        }
      }
    },
    UPDATE_SECTOR_MATERIAL(state, { id, val }) {
      state.sectors[id].material.id = val
    },
    UPDATE_SECTOR_MATERIAL_DENSITY(state, { sectorIndex, val }) {
      state.sectors[sectorIndex].material.density = val
    },
    SET_SECTOR_MINOR_RESULT_RECOMMENDED_PARAMS(state, { sectorIndex, zones }) {
      state.sectors[sectorIndex].minorResult = Object.fromEntries(
        Object.entries(state.sectors[sectorIndex].minorResult).map(([key, value]) => {
          return [key, zones.find(zone => zone.name === key).recommended ?? null]
        })
      )
    },
    UPDATE_SECTOR_MINOR_RESULT_RECOMMENDED_PARAM(state, { sectorIndex, param, val }) {
      state.sectors[sectorIndex].minorResult[param] = val
    },
    UPDATE_SECTOR_SIDE_DOUBLE_FASTENER(state, { sectorIndex, param, val }) {
      state.sectors[sectorIndex].base.type.doubleFastenerBySide[param] = val
    },
    CLEAR_SECTOR_MINOR_RESULT_RECOMMENDED_PARAMS(state, sectorIndex) {
      state.sectors[sectorIndex].minorResult = { ...defaultMinorResult }
    },
    CLEAR_ALL_SECTORS_MINOR_RESULT_RECOMMENDED_PARAMS(state) {
      state.sectors = state.sectors.map(sector => {
        return {
          ...sector,
          minorResult: { ...defaultMinorResult }
        }
      })
    },
    UPDATE_SECTOR_PARAM(state, payload) {
      state.sectors[payload.id][payload.param] = payload.val
    },
    UPDATE_SECTOR_STEP(state, payload) {
      state.sectors[payload?.id].inAppStep = payload.val
    },
    DELETE_SECTOR: (state, payload) => {
      state.sectors.splice(payload, 1)
    },
    ADD_NEW_SECTOR: state => {
      state.defaultSector.sectorName = `Участок ${state.sectors.length + 1}`
      state.defaultSector.uid = getRandomId()
      state.sectors.push(cloneDeep(state.defaultSector))
    },
    UPDATE_STATE_ARRAY: (state, payload) => {
      Vue.set(state, [payload.param], cloneDeep(payload.array))
    },
    UPDATE_RESULT_ARRAY: (state, payload) => {
      // console.log(state.result[payload.param]);
      state.result[payload.param] = cloneDeep(payload.array)
      // console.log(state.result[payload.param]);
      // state.sectors[payload.param].push(cloneDeep(payload.array));
    },
    UPDATE_RESULT: (state, payload) => {
      state.result = payload
    },
    UPDATE_STATE_OBJECT: (state, payload) => {
      state[payload.object] = payload.val
    },
    UPDATE_USER_INFO_PARAM(state, payload) {
      state.userInfo[payload.param] = payload.val
    },
    UPDATE_USER_INFO: (state, payload) => {
      Vue.set(state, 'userInfo', payload)
    },
    RESET_SECTORS(state) {
      state.sectors = []
      state.location.zone = ''
      state.location.pressure = ''
      state.location.type = null
      state.location.coefficient = 1.4
      state.location.zone = ''
      state.location.init = PRESSURE_UNITS.pa.name
      state.location.converted = null
      state.result.total = {}
      state.result.sectors = {}
    },
    UPDATE_CONTENT(
      state,
      {
        baseTypes,
        materials,
        mounting,
        categories,
        albums,
        fasteners,
        disclaimer,
        insulationTypes,
        insulationMethods
      }
    ) {
      state.insulationMaterials = Object.freeze(baseTypes)
      state.materials = Object.freeze(materials)
      state.mounting = Object.freeze(mounting)
      state.categories = Object.freeze(categories)
      state.albums = Object.freeze(albums)
      state.fasteners = Object.freeze(fasteners)
      state.insulationTypes = Object.freeze(insulationTypes)
      state.insulationMethods = Object.freeze(insulationMethods)
      const [firstInsulationType] = insulationTypes
      const [firstInsulationMethod] = insulationMethods
      state.defaultInsulationData.type = firstInsulationType.id
      state.defaultInsulationData.method = firstInsulationMethod.id
      state.sectors[0].insulation.data = { ...state.defaultInsulationData }
      state.defaultSector.insulation.data = { ...state.defaultInsulationData }
      state.disclaimer = state.disclaimer === null && !Array.isArray(disclaimer)
        ? [ disclaimer ]
        : Object.freeze(disclaimer)
    },
    UPDATE_PAGE_LOADING_STATUS: (state, { page, status }) => {
      state.pages[page].isLoading = status
    },
    UPDATE_SECTOR_BASE_TYPE_PARAM(state, { sectorIndex, param, val }) {
      state.sectors[sectorIndex].base.type[param] = val
    },
    UPDATE_SECTOR_DEPENDENCY_ON_ROLLS_SIZE(state, { sectorIndex, val }) {
      state.sectors[sectorIndex].isDependOnRollsSize = val
    },
    UPDATE_SECTOR_DEPENDENCY_ON_ROLLS_OPTIONS(state, { sectorIndex, options }) {
      state.sectors[sectorIndex].material.rollWidthOption = options
    },
    UPDATE_HASH(state, payload) {
      state.hash = payload.hash
    },
    UPDATE_CONTENT_LOADING_STATUS(state, payload) {
      state.pages.location.isLoading = payload
    },
    UPDATE_CALCULATION_RESULT(state, payload) {
      state.userInfo.result = payload
    },
    UPDATE_CALCULATION_RESULT_ID(state, payload) {
      if (state.userInfo.result === null) {
        state.userInfo.result = {}
      }
      state.userInfo.result.id = payload
    },
    RESET_CALCULATION_RESULT(state) {
      state.userInfo.result = null
    },
    UPDATE_SESSION_ID(state, payload) {
      state.userInfo.sessionId = payload
    },
    UPDATE_OBJECT_ID(state, payload) {
      state.userInfo.objectId = payload
    },
    UPDATE_CALCULATION_STATUS(state, payload) {
      state.saveCalculationLink = payload
    },
    UPDATE_MINOR_RESULTS(state, payload) {
      if (!state.minorResults) {
        state.minorResults = {}
      }
      if (!state.minorResults[payload.index]) {
        state.minorResults[payload.index] = {}
      }

      state.minorResults[payload.index][payload.label] = payload.data
      state.minorResults = { ...state.minorResults }
    },
    UPDATE_MESSAGES(state, payload) {
      state.messages = payload
    },
    ADD_PUBLIC_LINK(state, payload) {
      state.publicLink = payload
    }
  },
  getters: {
    getHash: state => state.hash,
    getTNToken(state) {
      return state.TNToken
    },
    getDisclaimer(state) {
      return state.disclaimer[state.disclaimerIndex]
    },
    getSector: state => id => state.sectors[id],
    getSectorResult: state => id => state.result.sectors[id],
    isClearMinorResultNeed: state => sectorIndex =>
      isSomeMinorResultZoneUnsetted(state.sectors[sectorIndex].minorResult),
    freeFixationInsulationMethodId: state =>
      state.insulationMethods.find(method => method.value === 'free_fixation').id,
    publicCalculationLink: state => state.publicLink
      ? `${state.publicLink}${state.userInfo.result.id}`
      : ``
  },
  actions: {
    addPublicLink({ commit }, privateLink) {
      const url = new URL(privateLink)
      commit('ADD_PUBLIC_LINK', `${url.origin}/calculators/roof-load/?object=`)
    },
    resetUserInfoResults({ commit }) {
      commit('RESET_CALCULATION_RESULT')
    },
    updateSectorInsulationMethod({ state, commit }, { sectorIndex, val, isNeedToUpdateMounting }) {
      commit('UPDATE_INSULATION_PARAM', {
        id: sectorIndex,
        param: 'method',
        val
      })
      if (isNeedToUpdateMounting) {
        const originalMounting = state.mounting.find(
          originalMounting => originalMounting.value === 'ballast'
        )
        commit('UPDATE_SECTOR_MATERIAL_MOUNTING', {
          sectorIndex,
          originalMounting
        })
      }
    },
    updateSectorDoubleFastenerStatus({ state, commit }, { sectorIndex, status }) {
      const targetSectorBase = state.sectors[sectorIndex].base
      const targetSectorBaseType = targetSectorBase.type
      const targetSectorBaseTypeId = targetSectorBaseType.id
      const isSectorBaseTypeCustom = targetSectorBaseType.isCustom
      const isSectorBaseTypeSelected = isSectorBaseTypeCustom ? false : !!targetSectorBaseTypeId
      commit('UPDATE_SECTOR_BASE_TYPE_PARAM', {
        sectorIndex,
        param: 'isDoubleFastener',
        val: status
      })
      commit('UPDATE_SECTOR_BASE_TYPE_PARAM', {
        sectorIndex,
        param: 'doubleFastenerBySide',
        val: { C: status, D: status, E: status }
      })
      if (status && isSectorBaseTypeSelected) {
        const originalMaterialType = state.insulationMaterials
          .find(material => material.id === targetSectorBase.id)
          .types.find(type => type.id === targetSectorBaseTypeId)
        const isSelectedBaseTypeHasDoubleFasteners = originalMaterialType.isDoubleFasteners
        if (!isSelectedBaseTypeHasDoubleFasteners) {
          commit('UPDATE_SECTOR_BASE_TYPE', {
            id: sectorIndex,
            material: null,
            size: null
          })
        }
      }
    },
    startNewCalculation({ commit }) {
      commit('RESET_SECTORS')
      commit('ADD_NEW_SECTOR')
      commit('UPDATE_CALCULATION_RESULT', null)
      commit('RESET_CALCULATION_RESULT')
      commit('UPDATE_CALCULATION_STATUS', null)
      router.push('/location')
    },
    getContent({ commit }) {
      getContent().then(response => {
        commit('UPDATE_CONTENT', response.data)
        commit('UPDATE_CONTENT_LOADING_STATUS', false)
        commit('UPDATE_MESSAGES', response.data?.messages)
      })
    },
    addSessionId({ commit }, sessionId) {
      commit('UPDATE_SESSION_ID', sessionId)
    },
    addObjectId({ commit }, ObjectId) {
      commit('UPDATE_OBJECT_ID', ObjectId)
    },
    markCalculationAsSaved({ commit }, flag) {
      commit('UPDATE_CALCULATION_STATUS', flag)
    },
    requestSavedResult({ commit }, { urlQuery }) {
      commit('UPDATE_PAGE_LOADING_STATUS', { page: pages.result, status: true })
      commit('UPDATE_CALCULATION_RESULT_ID', urlQuery)
      Promise.all([getContent(), getResultFromDB(urlQuery)])
        .then(response => {
          const [content, resultsData] = response
          const { location, sectors, result } = resultsData.data
          commit('UPDATE_CONTENT', content.data)
          commit('UPDATE_RESULT', JSON.parse(result))
          commit('UPDATE_STATE_OBJECT', {
            object: 'location',
            val: JSON.parse(location)
          })

          if (this.state.result?.pressure) {
            commit('UPDATE_LOCATION_PARAM', {
              param: 'converted',
              val: parseFloat(this.state.result?.pressure.pa)
            })
          }

          commit('UPDATE_STATE_OBJECT', {
            object: 'sectors',
            val: JSON.parse(sectors)
          })
          commit('UPDATE_MESSAGES', content.data?.messages)
          commit('UPDATE_PAGE_LOADING_STATUS', { page: pages.result, status: false })
        })
        .catch(() => {
          router.push('/404')
        })
    },
    requestResults({ state, commit }) {
      commit('UPDATE_PAGE_LOADING_STATUS', { page: pages.result, status: true })
      commit('UPDATE_OBJECT_ID', getObjectId())

      let link, id
      if (state.userInfo?.result) {
        link = state.userInfo.result.link
        id = state.userInfo.result.id
      }
      const forProfileData = (link || id) ? { link, id } : null

      getTotalResult(state.sectors, state.location, forProfileData, state.userInfo.objectId).then(response => {
        if (process.env.NODE_ENV === 'development') {
          // console.log(response.data)
        }
        commit('UPDATE_RESULT', normalizeBackendResults(response.data))
        if (!forProfileData) commit('UPDATE_CALCULATION_RESULT', response.data?.result)
        if (response.data?.pressure) {
          commit('UPDATE_LOCATION_PARAM', {
            param: 'converted',
            val: parseFloat(response.data?.pressure.pa)
          })
        }
        commit('UPDATE_PAGE_LOADING_STATUS', { page: pages.result, status: false })
      })
    },
    async sendMetrics({ state }, { stepNumber, sessionToken }) {
      const { sectors, location, result, userInfo, hash } = state
      try {
        await sendMetrics(sessionToken, stepNumber, userInfo, sectors, location, result, hash)
      } catch (e) {}
    },
    updateTNToken({ commit }) {
      commit('UPDATE_TN_TOKEN_STATUS', true)
    },
    checkTNToken({ commit }, token) {
      checkTNToken(token)
        .then(() => {
          commit('UPDATE_TN_TOKEN', token)
        })
        .catch(() => {
          commit('UPDATE_TN_TOKEN_STATUS', true)
          console.log('Невалидный ключ')
        })
    },
    saveMinorResults({ commit }, payload) {
      commit('UPDATE_MINOR_RESULTS', payload)
    }
  },
  modules: {}
})
