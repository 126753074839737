export const AIR_DENSITY = 1.225

export const PRESSURE_UNITS = {
  ms: { name: 'ms', label: 'м/с' },
  pa: { name: 'pa', label: 'КПа' }
}

export function convertPressure(input) {
  return ((0.5 * AIR_DENSITY * input * input) / 1000).toFixed(2)
}

export function getCombinedPressure(location) {
  const meter = `${location.pressure} ${PRESSURE_UNITS.ms.label}`
  const KPa = `${convertPressure(location.pressure)} ${PRESSURE_UNITS.pa.label}`

  return `${meter} - ${KPa}`
}

export function getPressureText(location) {
  return location.pressureUnit === PRESSURE_UNITS.ms.name
    ? getCombinedPressure(location) : `${location.pressure} ${PRESSURE_UNITS.pa.label}`
}
