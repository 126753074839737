export const stepNumbers = {
  home: 0,
  location: 1,
  Base: 2,
  Insulation: 3,
  DampCourse: 4,
  Sector: 5,
  MinorResult: 6,
  result: 7,
  details: 8
}
