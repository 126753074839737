import axios from 'axios'
import { strip } from '../utils/utils'

export const api = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? process.env.VUE_APP_API_URL
      : `${location.origin}/service_api/`,
  headers: {
    'Content-Type': 'application/hal+json',
    Accept: 'application/json'
  }
})
export const actionApi = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development' ? process.env.VUE_APP_ACTION_URL : `${location.origin}`,
  headers: {
    'Content-Type': 'application/hal+json',
    Accept: 'application/json'
  }
})
const ratingEndpoint = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? process.env.VUE_APP_RATING_URL
      : `${location.origin}/service_rating/`,
  headers: {
    'Content-Type': 'application/hal+json',
    Accept: 'application/json'
  }
})

export const getContent = () => {
  return api.get('/content')
}

export const getMinorResult = (sector, location) => {
  let formData = new FormData()
  formData.append('sector', JSON.stringify(sector))
  formData.append('location', JSON.stringify(location))
  return api.post('/calculate', formData)
}

export const getFastenersAverage = ({ form, params, height }, estimated, widths, materialId, isDependOnRollsSize) => {
  const formData = new FormData()
  formData.append('form', form)
  formData.append('params', JSON.stringify(params))
  formData.append('height', height)
  formData.append('estimated', JSON.stringify(estimated))
  formData.append('isDependOnRollsSize', isDependOnRollsSize)
  formData.append('widths', JSON.stringify(widths))
  formData.append('materialId', materialId)
  return api.post('/calculate_fasteners_average', formData)
}

export const getTotalResult = (sectors, location, result, objId) => {
  let formData = new FormData()
  formData.append('sectors', JSON.stringify(sectors))
  formData.append('location', JSON.stringify(location))
  if (result) formData.append('result', JSON.stringify(result))
  if (objId) formData.append('object', objId)
  return api.post('/calculate_total', formData)
}

export const getResultFromDB = urlQuery => {
  const hash = new FormData()
  hash.append('data', urlQuery)
  return api.post('/results_get', hash)
}

const createCommonPdfParts = (userInfo, result, count, location, publicCalculationLink = null) => {
  const { square } = result
  const { zone, type, pressure, pressureUnit, coefficient } = location
  const firstPage = {
    year: new Date().getFullYear()
  }
  if (publicCalculationLink) {
    firstPage.link = publicCalculationLink
  }
  const header = {
    square,
    zone,
    type,
    pressure,
    pressureUnit,
    coefficient
  }
  if (count !== null) {
    header.count = count
  }
  return { firstPage, header }
}

export const getFullResultsPdf = (
  userInfo,
  result,
  count,
  location,
  sectors,
  materialsTable,
  disclaimer,
  publicCalculationLink
) => {
  let formData = new FormData()
  const { firstPage, header } = createCommonPdfParts(userInfo, result, count, location, publicCalculationLink)
  const { albums, fasteners } = result
  const postData = {
    header,
    firstPage,
    materials: materialsTable,
    albums,
    fasteners,
    sectors,
    disclamer: strip(disclaimer)
  }

  if (location.converted !== null) {
    postData.pressure = {}
    postData.pressure.pa = location.converted
  }

  formData.append('type', 'pdf_total_sections')
  formData.append('data', JSON.stringify(postData))
  if (userInfo.result?.id) formData.append('result', userInfo.result.id)

  return api.post('/pdf', formData, {
    responseType: 'blob'
  })
}

export const getSectorResultPdf = (userInfo, albums, sector, materialsTable, disclaimer, publicCalculationLink, location) => {
  let formData = new FormData()
  const firstPage = {
    year: new Date().getFullYear(),
    link: publicCalculationLink
  }
  const postData = {
    isSector: true,
    firstPage,
    ...sector,
    albums,
    materials: materialsTable,
    disclamer: strip(disclaimer)
  }

  if (location.converted !== null) {
    postData.pressure = {}
    postData.pressure.pa = location.converted
  }

  formData.append('type', 'pdf_section')
  formData.append('sectionType', sector.sectionType)
  formData.append('image', sector.image)
  formData.append('data', JSON.stringify(postData))
  if (userInfo.result?.id) formData.append('result', userInfo.result.id)

  return api.post('/pdf', formData, {
    responseType: 'blob'
  })
}

export const sendRating = (object, hash) => {
  let formData = new FormData()
  formData.append('data', JSON.stringify(object))
  if (hash) formData.append('hash', hash)
  return ratingEndpoint.post('/vote', formData)
}
export const checkTNToken = token => {
  let formData = new FormData()
  formData.append('token', token)
  return api.post('/token_status', formData)
}
