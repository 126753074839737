import Vue from 'vue'
import Router from 'vue-router'
import { sendStatistic, statisticLabels } from '@/utils/sendStatistic'

Vue.use(Router)
export const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      beforeEnter: (to, from, next) => {
        localStorage.setItem('objId', from.query?.objid)
        next({ path: 'location' })
      }
    },
    {
      path: '/wizard/:id',
      name: 'Wizard',
      component: () => import('@/components/smart/Wizard.vue')
    },
    {
      path: '/location',
      name: 'Location',
      component: () => import('@/views/Location.vue')
    },
    {
      path: '/result',
      name: 'Result',
      component: () => import('@/views/Result.vue'),
      beforeEnter: (to, from, next) => {
        sendStatistic(statisticLabels.steps.result)
        next()
      }
    },
    {
      path: '/details/:id',
      name: 'Details',
      component: () => import('@/views/Details.vue')
    },
    {
      path: '/404',
      name: '404',
      component: () => import('@/views/404.vue')
    },
    {
      path: '/*',
      name: 'MissingPage',
      component: () => import('@/views/404.vue')
    }
  ],
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  next()
  window.parent.postMessage({ scrollTop: true }, '*')
})
