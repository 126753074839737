import { api } from '@/api/index'
export const sendMetrics = (sessionToken, stepNumber, userInfo, sectors, location, result, hash = null) => {
  let postData = new FormData()
  postData.append('date', new Date().toISOString())
  postData.append('sessionToken', sessionToken)
  postData.append('stepNumber', stepNumber)
  postData.append('data', JSON.stringify(sectors))
  postData.append('location', JSON.stringify(location))
  postData.append('result', JSON.stringify(result))
  if (hash) postData.append('hash', hash)
  if (userInfo) postData.append('userInfo', JSON.stringify(userInfo))
  return api.post('/metrics', postData)
}
